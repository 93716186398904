export const FetchLinkProp=(attribute)=>{

    let classes= '';
    let rel = '';
    let target = '';

    if(attribute?.class && attribute?.class?.length){
        classes=attribute.class.reduce((res, currVal,curInd)=>res+currVal+" ","")
    }
    
    if(attribute?.target && attribute?.target?.includes('blank') ){
        rel=attribute.rel
        target=attribute.target
    }

    return {classes, rel, target};
}
