import Dropdown from "./Dropdown";
import { Link } from "react-router-dom";
import { FetchLinkProp } from "../../utils/common";

const MenuItem = ({ items }) => {
  const test = items.url;
  const url = typeof test === "string" ? test.replace("/backend", "") : "";
  
  let attribute = items['attributes']?.attributes;
  const linkProp=FetchLinkProp(attribute)
 
  
  return (
    <>
      {/* mobile view */}
      <li className="nav-item dropdown d-block d-sm-block d-md-none">
        {items.below ? (
          <>
            <Link
              className={`nav-link dropdown-toggle ${linkProp?.classes}`}
              aria-expanded="false"
              to={url}
              id="navbarDropdown"
              role="button"
              data-bs-toggle
            >
              {items.title}
            </Link>
            <Dropdown submenus={items.below} />
          </>
        ) : (
          <Link
            className="nav-link dropdown-toggle"
            aria-expanded="false"
            to={url}
            id="navbarDropdown"
            role="button"
          >
            {items.title}
          </Link>
        )}
      </li>
      <li className="nav-item dropdown d-none d-md-block d-lg-block">
        {items.below ? (
          <>
            <a
              className="nav-link dropdown-toggle"
              aria-expanded="false"
              id="navbarDropdown"
              role="button"
              data-bs-toggle
            >
              {items.title}
            </a>
            <Dropdown submenus={items.below} />
          </>
        ) : (
          <Link
            className="nav-link dropdown-toggle"
            aria-expanded="false"
            to={url}
            id="navbarDropdown"
            role="button"
          >
            {items.title}
          </Link>
        )}
      </li>
    </>
  );
};

export default MenuItem;
