import React, { useEffect, useState } from "react";
import MenuItem from "./MenuItem";
import { Link } from "react-router-dom";

const Header = (props) => {
  const [menuItemsData, setMenuItemsData] = useState();
  const [logindata, setlogindata] = useState(false);
  const [loginpath, setloginpath] = useState();
  const [logintitle, setlogintitle] = useState();
  
  useEffect(() => {
    const API2 = `${process.env.REACT_APP_API_URL}/api/menu_items/dsci-login?format=json`;
    const API_KEY = process.env.REACT_APP_API_KEY;
   
    if(props.menuItemsData){
      setMenuItemsData(props.menuItemsData);
    }
   
    const fetchApiData2 = async (url) => {
      try {
        const res = await fetch(url, {
          headers: {
            "api-key": API_KEY,
          },
        });
        const data = await res.json();
        if (data && data?.length !== 0) {
          setlogindata(true);
        }

        if ((data[0].title !== undefined) & (data?.length !== 0)) {
          setlogintitle(data[0].title);
          setloginpath(data[0].relative);
        }
      } catch (error) {
        // console.log(error.response);
      }
    };
    fetchApiData2(API2);
  }, [props.menuItemsData]);

  
  return (
    <>
      <div className="top-header d-none-3 ">
        <div className="container-fluid">
          <div className="container container-main">
            <div className="row">
              <div className="col-md-2 col-6">
                <Link to="/">
                  <img
                    src={
                      process.env.REACT_APP_DOMAIN_BASE_URL +
                      "/files/logos/DSCI-White-1.svg"
                    }
                    alt="logo"
                    className="dsci-logo"
                  />
                </Link>
              </div>
              <div className="col-md-4 col-4 d-none d-lg-block d-md-block d-xl-block">
                <nav className="navbar navbar-expand-md navbar-light bg-trans">
                  <div className="container-fluid p-0">
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent2"
                      aria-controls="navbarSupportedContent2"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                      className="collapse navbar-collapse"
                      id="navbarSupportedContent2"
                    >
                      <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
                        {menuItemsData?.map((menu, index) => {
                          return <MenuItem items={menu} key={index} />;
                        })}
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
              <div className="col-md-6 col-6">
                <div className="float-end right-sidee">
                  <ul className="pe-5">
                    <Link to="/search">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/img/search-1.png"
                        }
                        alt="search"
                        style={{ width: "22px" }}
                      />
                    </Link>
                  { logindata ? <li className="nav-item dropdown dsci-login-menu">
                        <Link
                        to="/login"
                        className="dropdown-toggle"
                        role="button"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="fas fa-user-alt mr-3"></i>{" "}
                        <span className="mobile-only-2">Login</span>
                      </Link>
                         <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuLink"
                          >
                            <li>
                              <a
                                className="dropdown-item"
                                href={loginpath}
                                target="_blank"
                              >
                                {" "}
                                <i className="fas fa-user-alt mr-3"></i>
                                {logintitle}
                              </a>
                            </li>
                          </ul>
                   </li> :""}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
