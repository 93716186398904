import Dropdown from './Dropdown'
import { Link } from 'react-router-dom'
import { FetchLinkProp } from '../../utils/common';

const MenuItem = ({ items }) => {
    const test = items.url;
    const url = typeof test === 'string' ? test.replace("/backend", "") : '';
    
    let attribute = items['attributes']?.attributes;
    const linkProp=FetchLinkProp(attribute);
    

    return (
        <>
        <li className="nav-item dropdown d-block d-sm-block d-md-block d-lg-none">
            {items.below ? (
                <>
                    {(linkProp?.target && linkProp?.target==="_blank" ) ? <Link className={`nav-link dropdown-toggle ${linkProp?.classes}`} target={linkProp?.target} rel={linkProp?.rel} aria-expanded="false"  to={url} id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true">{items.title}</Link>: <Link className={`nav-link dropdown-toggle ${linkProp.classes}`} aria-expanded="false"  to={url} id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true">{items.title}</Link>} 
                    <Dropdown submenus={items.below} />
                </>
            ) : (
                (linkProp?.target && linkProp?.target==="_blank" ) ? <Link className={`nav-link ${linkProp.classes}`} target={linkProp?.target} rel={linkProp?.rel}  aria-expanded="false" to={url} id="navbarDropdown" role="button" >{items.title}</Link>:<Link className={`nav-link ${linkProp.classes}`}  aria-expanded="false" to={url} id="navbarDropdown" role="button" >{items.title}</Link>
            )}
        </li>
        {/* web view */}
        <li className="nav-item dropdown d-none d-md-none d-lg-block ">
            {items.below ? (
                <>
                    {(linkProp?.target && linkProp?.target==="_blank" ) ? <Link className={`nav-link dropdown-toggle ${linkProp.classes}`} target={linkProp?.target} rel={linkProp?.rel}  aria-expanded="false"  to={url} style={{pointerEvents:"none"}} id="navbarDropdown" role="button" >{items.title}</Link>:<Link className={`nav-link dropdown-toggle ${linkProp.classes}`}  aria-expanded="false"  to={url} style={{pointerEvents:"none"}} id="navbarDropdown" role="button" >{items.title}</Link>} 
                    <Dropdown submenus={items.below} />
                </>
            ) : (
                (linkProp?.target && linkProp?.target==="_blank" ) ? <Link className={`nav-link dropdown-toggle ${linkProp.classes}`} target={linkProp?.target} rel={linkProp?.rel} aria-expanded="false" to={url} id="navbarDropdown" role="button" >{items.title}</Link>: <Link className={`nav-link dropdown-toggle ${linkProp.classes}`}  aria-expanded="false" to={url} id="navbarDropdown" role="button" >{items.title}</Link>
            )}
        </li>
   </> );
};

export default MenuItem;

