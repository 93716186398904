import { Link } from "react-router-dom";
import React from "react";
import { FetchLinkProp } from "../../utils/common";

const Dropdown = ({ submenus }) => {
  return (
    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
      {submenus.map((submenu, index) => {
        let attribute = submenu['attributes']?.attributes;
        const linkProp=FetchLinkProp(attribute);
        return(
        <li key={index}>
          {(linkProp?.target && linkProp?.target==="_blank" ) ? (
            <Link
              className={`dropdown-item ${linkProp.classes}`}
              to={`${submenu.url.replace("backend/", "")}`}
              target={linkProp?.target} 
              rel={linkProp?.rel}
              reloadDocument
            >
              {submenu.title}
            </Link>
          ) : (
            <Link
              className={`dropdown-item ${linkProp.classes}`}
              reloadDocument
              to={`${submenu.url.replace("/backend", "")}`}
            >
              {submenu.title}
            </Link>
          )}
        </li>
      )
      })}
    </ul>
  );
};

export default Dropdown;
