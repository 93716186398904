import React from 'react';
import {Link} from "react-router-dom";
import { FetchLinkProp } from "../../utils/common";

const Dropdown = ({ submenus }) => {
    
    return (
        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            {submenus.map((submenu, index) => {
                let attribute = submenu['attributes']?.attributes;
                const itemProps = FetchLinkProp(attribute);
                
                return (
                    <React.Fragment key={index}>
                        { itemProps?.target?.length ? (
                        <li>
                            <Link className={`dropdown-item ${itemProps?.classes}`} target={itemProps?.target} rel={itemProps?.rel} reloadDocument to={`${submenu?.url?.replace("backend/", "")}`} aria-expanded="false" >{submenu.title}</Link>
                        </li>
                        ):(<li>
                            <Link className={`dropdown-item ${itemProps?.classes}`}  reloadDocument to={`${submenu.url.replace("backend/", "")}`} aria-expanded="false" >{submenu.title}</Link>
                        </li>)}
                    </React.Fragment>
                )
            })}
        </ul>
    );
};

export default Dropdown;


